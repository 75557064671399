import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import beauty1 from '../../assets/img/beauty/beauty1.jpg';
// import beauty2 from '../../assets/img/beauty/beauty2.jpg';
// import beauty3 from '../../assets/img/beauty/beauty3.jpg';
export function JumbotronContainer() {
  const beauty1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg';
  const beauty2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg';
  const beauty3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg';

  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          <Jumbotron.Container className='beauty1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    毫无疑问，您以自己的方式塑造着自己的人生，正是这能量场将您带到这艺术王国；现在是时候选择一款精美的杰作——感受与你的个人能量和能量场产生共鸣的艺术品!
                  </p>
                  <p>
                    美化和重新创造你周围的空间，让您和他人尽情享受这充满异国情调的现代艺术!
                  </p>
                  <p>这是展示您成功的真实且明晰的宣言!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  src={beauty1}
                  // src={require('../../assets/img/beauty/beauty1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='beauty2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    这些艺术杰作具有原始的工业感，只有这样的尺寸和重量才能让人感受到。
                  </p>
                  <p>
                    我所创作的很多作品都是无出其右的，且是只为您专门创作、独一无二的杰作！
                  </p>
                  <p>
                    当您想重新创造您周围的能量场时，没有什么能比这些复杂的雕刻更合适了。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={beauty2}
                  // src={require('../../assets/img/beauty/beauty2.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='beauty3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>想象一下，您站在一个高达3米、重达900公斤的雕塑旁边！</p>
                  <p>
                    当您的门厅、酒店、或祈祷室摆设这样着的艺术品,
                    诸如“坚固”和“牢不可破”这样的词语都不能描述您的本性和决心了。
                  </p>
                  <p>这些依据您的愿景而创作的艺术品时时刻刻激励鼓舞着您！</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={beauty3}
                  // src={require('../../assets/img/beauty/beauty3.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          <Jumbotron.Container className='beauty4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>真正无限的可能性……让您的想象美化你的空间!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          <div className='beauty1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    毫无疑问，您以自己的方式塑造着自己的人生，正是这能量场将您带到这艺术王国；现在是时候选择一款精美的杰作——感受与你的个人能量和能量场产生共鸣的艺术品!
                  </p>
                  <p>
                    美化和重新创造你周围的空间，让您和他人尽情享受这充满异国情调的现代艺术!
                  </p>
                  <p>这是展示您成功的真实且明晰的宣言!</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  src={beauty1}
                  // src={require('../../assets/img/beauty/beauty1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='beauty2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    这些艺术杰作具有原始的工业感，只有这样的尺寸和重量才能让人感受到。
                  </p>
                  <p>
                    我所创作的很多作品都是无出其右的，且是只为您专门创作、独一无二的杰作！
                  </p>
                  <p>
                    当您想重新创造您周围的能量场时，没有什么能比这些复杂的雕刻更合适了。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={beauty2}
                  // src={require('../../assets/img/beauty/beauty2.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />

                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='beauty3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>想象一下，您站在一个高达3米、重达900公斤的雕塑旁边！</p>
                  <p>
                    当您的门厅、酒店、或祈祷室摆设这样着的艺术品，诸如“坚固”和“牢不可破”这样的词语都不能描述您的本性和决心了。
                  </p>
                  <p>这些依据您的愿景而创作的艺术品时时刻刻激励鼓舞着您！</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={beauty3}
                  // src={require('../../assets/img/beauty/beauty3.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          <div className='beauty4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>真正无限的可能性……让您的想象美化你的空间!</p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
