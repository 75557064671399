import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import philosophy1 from '../../assets/img/philosophy/philosophy1.jpeg';
// import philosophy2 from '../../assets/img/philosophy/philosophy2.jpg';
// import philosophy3 from '../../assets/img/philosophy/philosophy3.jpg';
export function JumbotronContainer() {
  const philosophy1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpg';
  const philosophy2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg';
  const philosophy3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg';
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='philosophy1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我一直认为，成功的关键是创造一个美好的环境，从而创造一个充满
                    <span>希望</span>的天堂。而我仅单纯地希望人们喜欢我的作品。
                  </p>
                  <p>
                    我致力于在我的艺术领域内做最勇敢的那个人，因此我能够为您和您所挚爱的人创造出世界上最大、最复杂的金属艺术作品。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpeg'
                    philosophy1
                    // require('../../assets/img/philosophy/philosophy1.jpeg')
                    //   .default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='philosophy2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    这些巨大金属片组成的艺术品所散发出来的能量与您的能量和能量流彼此交融，创造您独有的能量场。
                  </p>
                  <p>
                    让想象力来设计您的周围环境并创造一种<span>富足感</span>。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    philosophy2
                    // require('../../assets/img/philosophy/philosophy2.jpg')
                    //   .default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='philosophy3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我也创作具有异域风情的现代<span>公共艺术作品</span>
                    ，让普通大众都有机会欣赏到这样的艺术创作。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    philosophy3
                    // require('../../assets/img/philosophy/philosophy3.jpg')
                    // .default
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <Jumbotron.Container className='philosophy1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我一直认为，成功的关键是创造一个美好的环境，从而创造一个充满
                    <span>希望</span>的天堂。而我仅单纯地希望人们喜欢我的作品。
                  </p>
                  <p>
                    我致力于在我的艺术领域内做最勇敢的那个人，因此我能够为您和您所挚爱的人创造出世界上最大、最复杂的金属艺术作品。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpeg'
                    philosophy1
                    // require('../../assets/img/philosophy/philosophy1.jpeg')
                    // .default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='philosophy2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    这些巨大金属片组成的艺术品所散发出来的能量与您的能量和能量流彼此交融,
                    创造您独有的能量场。
                  </p>
                  <p>
                    让想象力来设计您的周围环境并创造一种<span>富足感</span>。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    philosophy2
                    // require('../../assets/img/philosophy/philosophy2.jpg')
                    // .default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='philosophy3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我也创作具有异域风情的现代<span>公共艺术作品</span>
                    ，让普通大众都有机会欣赏到这样的艺术创作。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    philosophy3
                    // require('../../assets/img/philosophy/philosophy3.jpg')
                    // .default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
