import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';
// import commitment1 from '../../assets/img/commitment/commitment1.jpg';
// import commitment2 from '../../assets/img/commitment/commitment2.jpg';
export function JumbotronContainer() {
  const size = useWindowSize();
  const commitment1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg';
  const commitment2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg';
  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='commitment1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我的所有作品，在设计上极为细致和复杂，制作过程需要耗费大量的体力劳动、时间以及专业知识才能完成;
                    您可以想象一下，其所用的金属材料及其巨大的规格，有些作品将超过900多公斤!
                  </p>
                  <p>
                    这些作品的创作意义将远远超过购买一件当代异国情调的艺术作品;
                    它将对世界上那些不幸的人们产生巨大的意义和影响，因为我们将用收入一部分免费为他们提供食物……
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>在一起！</p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    // require('../../assets/img/commitment/commitment1.jpg')
                    commitment1 //.default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='commitment2'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text
                  className={size.width < 1000 ? 'displayNone' : ''}
                >
                  <p>在一起！</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我相信人生就像没有橡皮擦的绘画艺术;
                    所以，让我们选择为彼此创造美丽。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='commitment3'>
            <Jumbotron direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    // require('../../assets/img/commitment/commitment2.jpg')
                    commitment2 //.default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    这里所有的艺术作品都是独一无二的，我也可以根据您的特殊要求而创作定制的委托作品一只根据您的想象力进行创作，并让它栩栩如生地展现出来!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}
          <Jumbotron.Container className='commitment4'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>所有原创作品设计均为知识产权受版权保护。</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='commitment1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我的所有作品，在设计上极为细致和复杂，制作过程需要耗费大量的体力劳动、时间以及专业知识才能完成;
                    您可以想象一下，其所用的金属材料及其巨大的规格，有些作品将超过900多公斤!
                  </p>
                  <p>
                    这些作品的创作意义将远远超过购买一件当代异国情调的艺术作品;
                    它将对世界上那些不幸的人们产生巨大的意义和影响，因为我们将用收入一部分免费为他们提供食物……
                  </p>
                </Jumbotron.Text>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>在一起！</p>
                  </Jumbotron.Text>
                </div>
              ) : null}
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    // require('../../assets/img/commitment/commitment1.jpg')
                    commitment1 //.default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='commitment2'>
            <div>
              <div>
                <Jumbotron.Text
                  className={size.width < 1000 ? 'displayNone' : ''}
                >
                  <p>在一起！</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我相信人生就像没有橡皮擦的绘画艺术;
                    所以，让我们选择为彼此创造美丽。
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='commitment3'>
            <div direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    // require('../../assets/img/commitment/commitment2.jpg')
                    commitment2 //.default
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    这里所有的艺术作品都是独一无二的，我也可以根据您的特殊要求而创作定制的委托作品一只根据您的想象力进行创作，并让它栩栩如生地展现出来!
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}
          <div className='commitment4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>所有原创作品设计均为知识产权受版权保护。</p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
