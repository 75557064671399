import styled, { css } from 'styled-components/macro';

export const CopyrightMain = styled.div`
color: ${(props) => props.theme.text};
padding: 3rem 10%;
@media(max-width: 1000px){
    padding: 3rem 5%;
}
@media(max-width: 500px){
    padding: 3rem 7%;
}
`;
export const CopyrightLogo = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: -5rem 0;
svg {
    width: 300px
}
@media(max-width: 1000px){
    margin: -4rem 0;
    svg {
        width: 250px
    }
}
@media(max-width: 500px){
    margin: -3rem 0;
    svg {
        width: 200px
    }
}
`;
export const CopyrightHeader = styled.h2`
text-align: center;
font-weight: 600;
font-size: 3rem;
display: flex;
justify-content: center;
@media(max-width: 1000px){
    font-size: 2rem;    
}
@media(max-width: 500px){
    font-size: 2rem;    
}
@media(max-width: 385px){
    font-size: 1.8rem;    
}
`;
export const CopyrightStatement = styled.h2`
text-align: center;
font-weight: 600;
font-size: 2.6rem;
display: flex;
justify-content: center;
@media(max-width: 1000px){
    font-size: 2rem;    
}
@media(max-width: 500px){
    font-size: 1.6rem;    
}
@media(max-width: 355px){
    font-size: 1.5rem;    
}
`;
export const CopyrightSubHeading = styled.h4`
font-weight: bold;
margin: 20px auto;
font-size: 2.5rem;
@media(max-width: 1000px){
    font-size: 1.8rem;    
}
@media(max-width: 500px){
    font-size: 1.5rem;    
}
`;
export const CopyrightPara = styled.p`
margin-top: 0px;
margin-bottom: 12px;
line-height: 150%;
font-size: 1.4rem;
@media(max-width: 1000px){
    font-size: 1.2rem;    
}

@media(max-width: 768px) {
    font-size: 1.2rem;
}
@media(max-width: 500px) {
    font-size: 1rem;
}
`;
export const CopyrightEndBorder = styled.div`
border-top: 1px dashed;
border-color: ${(props) => props.theme.text};
margin-top: 1rem;
`;