import React, { useEffect } from 'react';
import {
  CopyrightHeader,
  CopyrightMain,
  CopyrightPara,
  CopyrightSubHeading,
  CopyrightStatement,
  CopyrightLogo,
} from '../styles/copyright';

//Styled Component
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

import CustomCursor from '../Components/CustomCursor';
import { CopyrightHomeAccordian } from '../Components/Homepage/HomeAbout';

import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from '../context/globalContext';

import { ReactComponent as PoloYSVG } from '../assets/svg/AO_Logo_Isotype_RGB-01.svg';
import { analytics } from '../firebase/index';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    text-decoration: none;
    cursor: none;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Lato', sans-serif;
    background: ${(props) => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
  }

  /*  @font-face {
      font-family: 'Caviar Dreams';
      font-style: normal;
      font-weight: 300;
      src: local('Caviar Dreams'), url('../assets/fonts/CaviarDreams.tff') format('woff');
    }*/


  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }

  ::-webkit-scrollbar {
    width: 2px;
    @media (max-width: 768px) {
      width: 0px;
      display: none;
      }
  }

  ::-webkit-scrollbar-track {
    background-color: #4e555b;
  }
`;

export default function Copyright({ setLinkSection, onCursor }) {
  useEffect(() => {
    analytics.logEvent('Copyright_Page_visited');
  }, []);

  const darkTheme = {
    background: '#000',
    text: '#fff',
    red: '#f42835',
    // red: 'linear-gradient(to right,#9c192f,5%,#f42835,20%,#aa0034,80%,#eb002a,95%,#b1212b)',
  };
  const lightTheme = {
    text: '#000',
    background: '#fff',
    red: '#f42835',
  };
  const { currentTheme, cursorStyles } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();

  return (
    <ThemeProvider theme={currentTheme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <CustomCursor />
      <CopyrightMain>
        {/* <CopyrightHeader>
                Ayusha Metal Art & Design Inc. <br /> Terms of Use Agreement, disclaimers and caveats.
                </CopyrightHeader> */}
        <CopyrightLogo>
          <PoloYSVG
            className={'poloySVG'}
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          />
        </CopyrightLogo>

        <CopyrightHeader>
          Ayusha Opus <br /> Metal Art & Design Inc.
        </CopyrightHeader>

        <CopyrightStatement>使用协议</CopyrightStatement>

        <CopyrightPara>
          当您使用本网站时，即表示您已阅读本网站所载的使用条款协议及免责声明且受其约束。
        </CopyrightPara>
        <CopyrightSubHeading>一般条款</CopyrightSubHeading>
        <CopyrightPara>
          在使用Ayusha Opus Metal Art & Design
          Inc.之前，请仔细阅读本页的所有信息。网站由Ayusha Opus Metal Art &
          Design Inc.（法人）和Nilesh Bhathella运营。
        </CopyrightPara>
        <CopyrightPara>
          本网站所展示的内容为Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella的知识产权。未经我们正式明确的书面同意，您不得重复使用、再版或重印此类内容。
        </CopyrightPara>
        <CopyrightPara>
          所有资料仅供教育及资讯之用。它不能替代任何专业意见。如果您决定根据本网站的任何信息采取行动，风险由您自己承担。
        </CopyrightPara>
        <CopyrightPara>
          虽然本网站的资料已尽我们所能核实，但我们不能保证没有任何错误。
        </CopyrightPara>
        <CopyrightPara>
          我们保留随时更改条款的权利，但不会及时向您更新相关信息；在每次使用本网站之前请您务必阅读并接受此免责声明，并获取最新变动信息。
        </CopyrightPara>
        <CopyrightPara>
          如果您想要了解最新变动，我们强烈建议您经常访问这个页面。
        </CopyrightPara>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella对本网站包含的信息和材料的准确性或完整性不作任何保证，也不承担任何责任。在任何情况下，不管是由于您使用或无法使用本网站或任何网站、流媒体或与之相连的社交媒体引起的，还是由于您对本网站上的信息和材料的依赖直接或间接导致引起的，
          即使Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella事先被告知这种损害的可能性，Ayusha Opus Metal Art & Design
          Inc.和Nilesh
          Bhathella不接受以任何方式对其要求的索赔、损害、损失、费用、成本或责任(包括但不限于任何直接或间接利润损失、
          业务中断或信息丢失）。
        </CopyrightPara>
        <CopyrightPara>
          在本网站或通过本网站表达的观点仅代表作者个人的观点。本网站特此声明不承担与基于本网站内容采取或不采取行动相关的所有责任。本网站并没有声明网站内容是无错误的。
        </CopyrightPara>

        <CopyrightSubHeading id='copyright'>版权</CopyrightSubHeading>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc./ Nilesh Bhathella
        </CopyrightPara>
        <CopyrightPara>
          ©2021 Ayusha Opus Metal Art & Design Inc.保留所有权利。
        </CopyrightPara>
        <CopyrightPara>©Nilesh Bhathella 2021保留所有权利。</CopyrightPara>
        <CopyrightPara>
          作者（Nilesh Bhathella）和所有相关作品的权利受《加拿大版权法》(R.S.C.,
          1985, c.
          C-42)、1976年的《联邦版权法》和1990年的《联邦视觉权利法》保护。
          购买受版权保护的艺术品并不会转让版权。制作复制品、基于该复制品制作衍生作品、发行复制品的权利仅由作者所有。任何版权的转让都必须以书面形式得到正式同意，明确说明出售的是什么权利以及目的是什么；
          除此之外，艺术作品及其版权均由作者(Nilesh Bhathella)和Ayusha Opus
          Metal art & Design Inc.独家保留。
        </CopyrightPara>
        <CopyrightPara>
          未经作者许可，不得修改作品。艺术作品不得以任何方式转载、损毁或修改，以免损害作者的声誉。
        </CopyrightPara>
        <CopyrightPara>
          主视频和预告片视频由Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella享有完全版权。所有内容都不得以任何方式复制
        </CopyrightPara>
        <CopyrightPara>
          本网站所展示的所有艺术作品完全由Ayusha Opus Metal art & Design
          Inc.和Nilesh Bhathella版权所有。所有内容都不得以任何方式复制。
        </CopyrightPara>
        <CopyrightPara>
          本网站所展示的所有照片、图片完全由Ayusha Opus Metal Art & Design
          Inc.和Nilesh Bhathella拥有版权。所有内容都不得以任何方式复制。
        </CopyrightPara>
        <CopyrightPara>
          本网站内的所有文字内容完全由Ayusha Opus Metal Art & Design
          Inc.和Nilesh Bhathella版权所有。所有内容都不得以任何方式复制。
        </CopyrightPara>

        <CopyrightSubHeading>一、版权声明 </CopyrightSubHeading>
        <CopyrightPara>
          1.1 版权©Ayusha Opus Metal Art & Design Inc.
        </CopyrightPara>
        <CopyrightPara>1.2 版权©Nilesh Bhathella</CopyrightPara>
        <CopyrightPara>1.3 除本协议的明确规定外:</CopyrightPara>
        <CopyrightPara>
          (1)
          我们与我们的许可方共同拥有并控制本网站的所有版权和其他知识产权以及本网站上的材料；
        </CopyrightPara>
        <CopyrightPara>
          (2) Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella独家拥有本网站及本网站资料的所有版权及其他知识产权。这包括但不限于在社交媒体账户上发布的作品，如Facebook、Twitter、Instagram和所有其他形式的媒体下的Ayusha
          Opus Metal Art & Design Inc. -- AO标识。
        </CopyrightPara>

        <CopyrightSubHeading>二、使用权限</CopyrightSubHeading>
        <CopyrightPara>2.1 您可以: </CopyrightPara>
        <CopyrightPara>(1) 在网页浏览器中浏览本网站的网页；</CopyrightPara>
        <CopyrightPara>(2) 从我们的网站传输音频和视频文件；</CopyrightPara>
        <CopyrightPara>
          (3) 从我们的网站下载网页，其唯一目的是在网页浏览器内缓存下载的网页；
        </CopyrightPara>
        <CopyrightPara>2.2 您不得:</CopyrightPara>
        <CopyrightPara>
          (4)
          打印、复制本网站的任何网页或任何项目、图片或视频；除非本协议有其他明确规定。
        </CopyrightPara>
        <CopyrightPara>
          2.3
          您不得从本网站下载任何材料或将任何材料保存到您的计算机或任何其他存储设备，除非本协议其他条款明确允许。
        </CopyrightPara>
        <CopyrightPara>
          2.4
          您只能为您自己的个人目的使用本网站，您不得为任何其他目的使用本网站。
        </CopyrightPara>
        <CopyrightPara>
          2.5
          除非本协议明确允许，否则您不得编辑或以其他方式修改本网站上的任何材料。
        </CopyrightPara>
        <CopyrightPara>
          2.6 除非您拥有或控制资料的相关权利，否则你不得：
        </CopyrightPara>
        <CopyrightPara>
          (1) 从本网站转载材料(包括在其他网站上转载)；
        </CopyrightPara>
        <CopyrightPara>(2) 出售、出租或转授权本网站的材料；</CopyrightPara>
        <CopyrightPara>(3) 公开展示本网站的任何材料；</CopyrightPara>
        <CopyrightPara>
          (4) 为商业目的或货币收益使用本网站的材料；
        </CopyrightPara>
        <CopyrightPara>
          (5) 转载本网站的材料，但在本协议明确允许范围内除外。
        </CopyrightPara>

        <CopyrightSubHeading>三、禁止使用</CopyrightSubHeading>
        <CopyrightPara>3.1 您不能：</CopyrightPara>
        <CopyrightPara>
          (1)
          以任何方式使用我们的网站或采取任何行动，造成或可能造成对本网站的损害，或对本网站的性能、可用性或可访问性的损害；
        </CopyrightPara>
        <CopyrightPara>
          (2)
          以任何非法、违法、欺诈性或有害的方式使用本网站，或与任何非法、违法、欺诈性或有害的目的或活动有关；
        </CopyrightPara>
        <CopyrightPara>
          (3)
          使用本网站复制、储存、托管、传输、发送、使用、发布或分发由间谍软件、计算机病毒、特洛伊木马、蠕虫、键盘记录器、提权软件包或其他恶意计算机软件组成(或链接)的任何材料；
        </CopyrightPara>
        <CopyrightPara>
          (4)
          在未经我们正式明确书面同意的情况下，在本网站上或与本网站有关的地方进行任何系统或自动化的数据收集活动（包括但不限于抓取、数据挖掘、数据提取和数据收集）。
        </CopyrightPara>

        <CopyrightSubHeading>四、滥用告知</CopyrightSubHeading>
        <CopyrightPara>
          4.1
          如果您获悉本网站上有任何违法材料或活动，或任何违反本协议的材料或活动，请告知我们。
        </CopyrightPara>
        <CopyrightPara>4.2 您可以通过电子邮件告知我们。</CopyrightPara>

        <CopyrightSubHeading>五、版权赔偿</CopyrightSubHeading>
        <CopyrightPara>5.1 我们非常重视对版权的保护。</CopyrightPara>
        <CopyrightPara>
          5.2
          如果我们发现您使用本网站的版权资料或艺术作品违反此协议所列的许可情况，我们会对您提起法律诉讼,要求货币赔偿并禁止您使用任何版权艺术作品和其他资料。
          您也将被要求支付任何有关违反上述规定的法律费用。
        </CopyrightPara>

        <CopyrightSubHeading>六、请求方式</CopyrightSubHeading>
        <CopyrightPara>
          6.1
          您可以使用本网站公布的联系方式，通过写信或电子邮件向我们提出使用本网站版权材料的请求。否则，将导致违反版权使用的规定。
        </CopyrightPara>

        <CopyrightSubHeading>七、版权许可</CopyrightSubHeading>
        <CopyrightPara>
          版权所有者和作者为Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella。
        </CopyrightPara>
        <CopyrightPara>
          版权完全属于版权所有人(也称为权利持有人)。
          因此，复制、展示或传播本网站内的作品，如任何艺术作品、视频、图片、文章或书面作品，均须获得版权所有人的许可。
        </CopyrightPara>

        <CopyrightSubHeading>八、版权期限</CopyrightSubHeading>
        <CopyrightPara>
          所有作品在作者生前以及作者死亡后的五十年内都受《版权法》的保护，
          之后艺术作品进入公共领域，任何人都可以未经许可的使用，除非Ayusha Opus
          Metal Art & Design
          Inc.在作者死后合法转移公司所有权，版权应认定为属于Ayusha Opus Metal
          Art & Design Inc.和AO品牌，并分别继续享有版权和商标。
        </CopyrightPara>
        <CopyrightPara>
          根据《保护文学和艺术作品伯尔尼公约》规定的国民待遇原则，艺术作品在《版权法》规定的期限内受到保护。
        </CopyrightPara>

        <CopyrightSubHeading>九、国际保护</CopyrightSubHeading>
        <CopyrightPara>
          加拿大和美国自1970年以来一直是世界知识产权组织(WIPO)的成员。
          WIPO还签署了多项国际知识产权条约，如1886年的《保护文学和艺术作品伯尔尼公约》，该公约为国际版权保护确立了指导原则。
        </CopyrightPara>

        <CopyrightSubHeading>十、版权特别声明</CopyrightSubHeading>
        <CopyrightPara>
          本网站使用的版权资料并不总是得到版权所有人的特别授权。我们使用这些可获得材料是为了增进人们对慈善本质的理解，增强对世界上那些缺乏基本生存物资（食物和水）的不幸的人们的认识，我们也感谢所有人在这方面的合作。我们无意以任何方式将受版权保护的作品用于再分配或获得经济收益。这构成了美国版权法第107条规定的任何此类受版权保护材料的“合理使用”。
        </CopyrightPara>
        <CopyrightPara>
          根据U.S.C.第17条第107款，某些特定材料可在本网站上使用。
          如果您希望将本网站受版权保护的资料用于您自己超出“合理使用”的目的，您必须首先获得版权所有人的许可。
        </CopyrightPara>
        <CopyrightPara>
          请在这样做之前给我们发送电子邮件，否则将被视为侵权。
        </CopyrightPara>
        <CopyrightPara>
          使用的某些产品和公司名称是其各自所持有人的商标™或注册®商标。
          我们使用它们并不意味着与它们有任何联系或认可。
        </CopyrightPara>
        <CopyrightPara>
          在本网站使用的某些作品，如版权不属于Ayusha Opus Metal Art & Design
          Inc.和Nilesh Bhathella或其指定代表，其作者有权做以下事情：
        </CopyrightPara>
        <CopyrightPara>
          以任何形式制作、复制、发表自己的作品或重要部分;
          在公众场合播放或展示他们自己的作品或重要部分；{' '}
        </CopyrightPara>
        <CopyrightPara>
          制作、复制、表演或出版自己作品的任何翻译版本；
        </CopyrightPara>
        <CopyrightPara>
          对其文学、戏剧或音乐作品作任何声音或电影录制；
        </CopyrightPara>
        <CopyrightPara>
          以电影作品的形式复制、改编和公开呈现他们自己的文学、戏剧、音乐或艺术作品；
        </CopyrightPara>
        <CopyrightPara>
          通过媒体向公众传播他们的文学、戏剧、音乐或艺术作品；{' '}
        </CopyrightPara>
        <CopyrightPara>在公开展览上展示他们的艺术作品； </CopyrightPara>
        <CopyrightPara>作者及指定代理人也负责授权上述操作。</CopyrightPara>

        <CopyrightSubHeading id='trademarks'>十一、商标</CopyrightSubHeading>
        <CopyrightPara>
          根据《商标法》(R.S.C., 1985, c.
          T-13)和任何随后的商标法规定：AO和Ayusha Opus Metal Art & Design
          Inc.均为注册商标，任何人或公司不得为任何目的以任何形式使用。
          AO、Ayusha Opus、Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella均已在本网站中使用，且被使用本网站的人明确视为注册商标。
        </CopyrightPara>
        <CopyrightPara>AO® 版权所有</CopyrightPara>
        <CopyrightPara>AO™ 版权所有</CopyrightPara>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc.® 版权所有{' '}
        </CopyrightPara>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc.™ 版权所有
        </CopyrightPara>

        <CopyrightSubHeading>十二、商标注意事项</CopyrightSubHeading>
        <CopyrightPara>
          除标识产品或服务的目的外，本协议未将许可方的任何商标或服务标志中的任何权利、所有权、收益或许可授予经销商。在商标所有人(Ayusha
          Opus Metal Art & Design Inc.和Nilesh
          Bhathella)的书面同意下，经销商可在其名片上表明其授权经销商身份。经销商不得对该商标的有效性或许可方对该商标的独家所有权提出异议。在本协议期限内，经销商不得在全球任何地区采用、使用或注册标志，无论是作为公司名称、商标、服务标志或其他原始标志，或与之混淆的任何词语或标志。
        </CopyrightPara>

        <CopyrightSubHeading>十三、艺术品的知识产权 </CopyrightSubHeading>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella（许可方）完全拥有Ayusha Opus Metal Art & Design
          Inc.的所有权利、所有权和利益，包括所有相关知识产权、技术、内容、艺术品或服务，以及您或他人提供的与艺术品或服务有关的任何建议、想法、改进要求、反馈、建议或其他信息。本协议并不表明出售或向您转让艺术作品或服务或与艺术作品、技术相关的权利，所有艺术作品、技术和知识产权由Ayusha
          Opus Metal Art & Design Inc.和Nilesh Bhathella独家拥有。 Ayusha
          Opus名称、AO徽标以及与艺术作品或服务相关的产品名称均为Ayusha Opus
          Metal Art & Design和Nilesh
          Bhathella的商标，且未授予他人以任何形式使用这些商标的权利或许可。
        </CopyrightPara>

        <CopyrightSubHeading>十四、网站的知识产权 </CopyrightSubHeading>
        <CopyrightPara>
          本网站及其相关软件为AO®Ayusha Opus Metal Art & Design Inc.和Nilesh
          Bhathella的知识产权，并由其拥有。
          本网站及其相关软件的结构、组织和代码包含AO®Ayusha Opus Metal Art &
          Design Inc.和Nilesh Bhathella的商业秘密和机密信息。
          除本协议另有明确规定，本协议并未授予您本网站及其相关软件的任何知识产权，所有权利由AO®Ayusha
          Opus Metal Art & Design Inc.和Nilesh
          Bhathella保留。在与任何个人或公司的合作关系中，AO®Ayusha Opus Metal
          Art & Design Inc.和Nilesh
          Bhathella享有全部或部分修改、构思、制作或开发作品形式、数据库或软件(包括任何共同开发的)的权利，并构成AO®Ayusha
          Opus Metal Art & Design Inc.和Nilesh
          Bhathella的独有财产，您完全同意不主张任何此类作品形式、数据库或软件的权利或所有权。基于您提供的样式、模板或范例而由AO®Ayusha
          Opus Metal Art & Design Inc.和Nilesh
          Bhathella专门为您创建的定制样式，您需向AO®Ayusha Opus Metal Art &
          Design Inc.和Nilesh
          Bhathella保证并提供您创建了上述样式或拥有使用上述样式的合法权利。如果任何第三方就您提供给AO®Ayusha
          Opus Metal Art & Design Inc.和Nilesh
          Bhathella的资料创建的样式、模板或范例向AO®Ayusha Opus Metal Art &
          Design Inc.和Nilesh
          Bhathella提出侵权、挪用或其他侵犯第三方知识产权的索赔，您同意对AO®Ayusha
          Opus Metal Art & Design Inc.和Nilesh Bhathella进行赔偿。
        </CopyrightPara>

        {/* <CopyrightSubHeading>In Short</CopyrightSubHeading>
                <CopyrightPara>What I am saying is:</CopyrightPara>
                <CopyrightPara>Do not copy any of my work in any way for any reason, that's it!</CopyrightPara>
                <CopyrightPara>Thank you ;)</CopyrightPara>
                <CopyrightPara>Sincerely,</CopyrightPara>
                <CopyrightPara>Nilesh Bhathella</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc.</CopyrightPara> */}

        {/* <CopyrightEndBorder /> */}
        <CopyrightHomeAccordian onCursor={onCursor} />
      </CopyrightMain>
    </ThemeProvider>
  );
}
