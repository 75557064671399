/* eslint-disable */

import React, { useEffect } from 'react';
import { FullWidth } from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Links from '../Components/Shared/Links';
import { EctraSpace } from '../styles/globalStyle';
import { JumbotronContainer } from '../containers/commitment/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Commitment = ({
  onCursor,
  toggleMenu,
  setToggleMenu,
  setLinkSection,
}) => {
  useEffect(() => {
    analytics.logEvent('Commitment_Page_visited');
    setToggleMenu(false);
  }, []);
  const content = (
    <FullWidth>
      <p>
        {`The work that I do is extremely detailed and complex in design, labor
        intensive and takes an incredible amount of time to complete; as you can
        imagine some of these pieces will weigh in excess of 2000 lbs. (900+
        kg.)~.! ${(
          <ectraSpace style={{ margin: '0.5rem' }} />
        )} …depending on the size and metal used.`}
      </p>
      <p>
        A <span>C</span>reation of this opus will mean much more than purchasing
        just another piece of modern contemporary exotic art; it will have
        enormous meaning and impact to those less fortunate around the world
        that we will help to nourish…
      </p>
      <h2>
        <span>T</span>ogether!
      </h2>
      <p>
        I believe that <span>L</span>ife is the Art of Drawing without an
        eraser; so let our life choices create beauty for one another.
      </p>
      <p>
        Though all of my works of art are unique limited and one of a kind
        masterpieces, I may be inclined by special request to create{' '}
        <span>B</span>espoke commissioned art - defined only by the limits of
        your own imagination and make it come alive ~!
      </p>
      <p> </p>
      <p>
        All original masterpiece designs are copyright as intellectual property.
      </p>
    </FullWidth>
  );
  return (
    <div>
      <ProjectBanner
        title='承诺'
        page='Commitment'
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      {/* <ProjectContent credits={false} content={content} /> */}
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Commitment;
