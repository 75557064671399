import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import metal1 from '../../assets/img/metal/metal1.jpg';
// import metal2 from '../../assets/img/metal/metal2.jpg';
export function JumbotronContainer() {
  const metal1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg';
  const metal2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg';

  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='metal1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我的<span>画布</span>
                    可以说是由金属制成的，特别是钢，铜，黄铜，青铜和铝！设计奇异复杂且充满异国情调，尺寸范围从5英尺（154.4厘米)到10
                    +英尺(304.8 +厘米)，厚度 1英寸(2.54厘米)。
                  </p>
                  <p>这是世界上最大最宏伟的雕刻。</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={metal1}
                  // src={require('../../assets/img/metal/metal1.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='metal2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    根据您的需求，我们奢华的铜绿饰面能为您添加非常个性化的签名和设计；虽然我更喜欢钢铁本身简单且纯粹的原始能量。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={metal2}
                  // src={require('../../assets/img/metal/metal2.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='metal3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>令人惊叹，独一无二，这是我的专长！</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='metal1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我的<span>画布</span>
                    可以说是由金属制成的，特别是钢，铜，黄铜，青铜和铝！设计奇异复杂且充满异国情调，尺寸范围从5英尺（154.4厘米)到10
                    +英尺(304.8 +厘米)，厚度 1英寸(2.54厘米)。
                  </p>
                  <p>这是世界上最大最宏伟的雕刻。</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={metal1}
                  // src={require('../../assets/img/metal/metal1.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='metal2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    根据您的需求，我们奢华的铜绿饰面能为您添加非常个性化的签名和设计；虽然我更喜欢钢铁本身简单且纯粹的原始能量。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={metal2}
                  // src={require('../../assets/img/metal/metal2.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='metal3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>令人惊叹，独一无二，这是我的专长！</p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
