import React, { useRef, useState } from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import { useGlobalStateContext } from '../../context/globalContext';
// import './index.scss';
import './index.css';
// import AO_Logo_on_black from '../../assets/img/meaning/AO_Logo_on_black.png';
// import AO_Logo_on_white from '../../assets/img/meaning/AO_Logo_on_white.png';
// import meaning1 from '../../assets/img/meaning/meaning1.jpg';
export function JumbotronContainer() {
  const [toggle, setToggle] = useState(false);
  const ripple = useRef(null);
  const AO_Logo_on_black =
    'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png';
  const AO_Logo_on_white =
    'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png';
  const meaning1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg';

  setTimeout(function () {
    // toggle && console.log('Delayed True')
    toggle && setToggle(!toggle);
  }, 500);

  const { currentTheme } = useGlobalStateContext();
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <div className='meaning1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    什么是Ayusha Opus，为什么我选择这个名字来代表我所做的这一切?
                  </p>
                  <p>
                    这个名字是一些特殊名词的集合，它所代表的多重含义正好能解释我的艺术内涵。
                  </p>
                  <p>
                    <span>A</span>
                    yusha起源于梵文，意思是“年龄、寿命和血统”。但在阿拉伯语中，Ayusha的意思是“生活得很好的人，拥有美好生活的人，被赋予繁荣和希望的人”。
                  </p>
                  <p>
                    <span>O</span>pus意为“艺术作品，尤指大型作品”。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                {/* <br /><br /> */}
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                        .default
                      : AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                        //.default
                  }
                  alt=''
                /> */}
                <div class={`hover15 columnss`}>
                  <div>
                    <figure
                      ref={ripple}
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      className={`${toggle && 'rippleForMobile'}`}
                    >
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                {/* <br /><br />    <br />    <br /> */}
                {/* <div className='overlay'></div> */}
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='meaning2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    我选择这个组合作为艺术品的名称既体现作品的壮丽辉煌，也可以成为彰显您成功的象征，您还可把它作为珍贵的礼物赠予你的友人。
                  </p>
                  <p>
                    这组合的本质在于我们所做的一切——阿尔法和欧米加——从始至终，永不停歇。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  src={meaning1}
                  // src={require('../../assets/img/meaning/meaning1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='meaning1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    什么是Ayusha Opus，为什么我选择这个名字来代表我所做的这一切?
                  </p>
                  <p>
                    这个名字是一些特殊名词的集合，它所代表的多重含义正好能解释我的艺术内涵。
                  </p>
                  <p>
                    <span>A</span>
                    yusha起源于梵文，意思是“年龄、寿命和血统”。但在阿拉伯语中，Ayusha的意思是“生活得很好的人，拥有美好生活的人，被赋予繁荣和希望的人”。
                  </p>
                  <p>
                    <span>O</span>pus意为“艺术作品，尤指大型作品”。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <div className='full-landing-image'></div>
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING full-landing-image'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                          //.default
                      : AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                  }
                  alt=''
                /> */}

                <div class='hover15 columnss'>
                  <div>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black //require('../../assets/img/meaning/AO_Logo_on_black.png')
                            : //.default
                              // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white //require('../../assets/img/meaning/AO_Logo_on_white.png')
                          //.default
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                {/* <div className='overlay'></div> */}
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='meaning2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    我选择这个组合作为艺术品的名称既体现作品的壮丽辉煌，也可以成为彰显您成功的象征，您还可把它作为珍贵的礼物赠予你的友人。
                  </p>
                  <p>
                    这组合的本质在于我们所做的一切——阿尔法和欧米加——从始至终，永不停歇。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  src={meaning1}
                  // src={require('../../assets/img/meaning/meaning1.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
