import React, { useEffect } from 'react';
import {
  FullWidth,
  ProjectContentWrapper,
} from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import ProjectVideos from '../Components/Project/ProjectVideos';
import Links from '../Components/Shared/Links';
import { JumbotronContainer } from '../containers/vision/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Vision = ({ onCursor, toggleMenu, setToggleMenu, setLinkSection }) => {
  useEffect(() => {
    analytics.logEvent('Vision_Page_visited');
    setToggleMenu(false);
  }, []);

  return (
    <div>
      <ProjectBanner
        className='vision'
        title={'愿景与慈善'}
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
        page={'vision'}
      />
      <br />
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Vision;
