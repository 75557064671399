import React, { useEffect } from 'react';
//Scroll Behaviour
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { Container } from '../../styles/globalStyle';
import {
  Content,
  HomeContentSection,
  ExtraSpace,
} from '../../styles/homeStyles';

const HomeContent = () => {
  const animation = useAnimation();
  const [contentRef, inVew] = useInView({
    triggerOnce: true,
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inVew) {
      animation.start('visible');
    }
  }, [inVew, animation]);
  return (
    <HomeContentSection ref={contentRef} animate={animation}>
      <Container>
        <Content className='homePage'>
          超越非凡
          <ExtraSpace class='mobile'>~远离平凡</ExtraSpace>
        </Content>
      </Container>
    </HomeContentSection>
  );
};

export default HomeContent;
