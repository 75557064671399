import React, { useEffect, useRef } from 'react';

// Scroll Animations
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

//Styled Components
import { Container, Flex } from '../styles/globalStyle';
import { FooterNav, FooterContent, FooterSocial } from '../styles/footerStyle';

//Icons
import {
  Instagram,
  Facebook,
  Vimeo,
  Twitter,
  Pinterest,
} from '../assets/svg/social-icons';

//Custom Hooks
import useElementPosition from '../hooks/useElementPosition';

import { FooterCopyright } from './Homepage/HomeAbout';

const Footer = ({ setHamburgerPosition, onCursor }) => {
  const instagramRef = useRef(null);
  const instagramPosition = useElementPosition(instagramRef);

  const facebookRef = useRef(null);
  const facebookPosition = useElementPosition(facebookRef);

  const twitterRef = useRef(null);
  const twitterPosition = useElementPosition(twitterRef);

  const pinterestRef = useRef(null);
  const pinterestPosition = useElementPosition(pinterestRef);

  const animation = useAnimation();
  const [footerRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-50px',
  });
  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView]);

  const menuHover = (x) => {
    onCursor('locked');
    setHamburgerPosition({ x: x });
  };

  return (
    <FooterNav
      ref={footerRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] },
        },
        hidden: { opacity: 0, y: 72 },
      }}
    >
      <Container>
        <Flex spaceBetween className={'flex'}>
          <Flex spaceBetween className={'flex-child'}>
            <FooterContent>
              {/* <p>902.315.1279</p> */}
              <p>sales@ayushaopus.com</p>
            </FooterContent>
            {/* <FooterContent wider>
                            <p>15 Camburhill Ct Unit C</p>
                            <p>Charlottetown, PE C1E 0E2</p>
                        </FooterContent> */}
          </Flex>
          <FooterSocial>
            <a
              onMouseEnter={() => menuHover(facebookPosition.x)}
              onMouseLeave={onCursor}
              ref={facebookRef}
              href='https://www.facebook.com/ayushaopus'
              target='_blank'
              className='facebook'
              rel='noreferrer'
            >
              <Facebook />
            </a>
            <a
              onMouseEnter={() => menuHover(instagramPosition.x)}
              onMouseLeave={onCursor}
              ref={instagramRef}
              href='https://www.instagram.com/ayushaopus/'
              target='_blank'
              rel='noreferrer'
            >
              <Instagram />
            </a>
            <a
              onMouseEnter={() => menuHover(twitterPosition.x)}
              onMouseLeave={onCursor}
              ref={twitterRef}
              href='https://twitter.com/ayushaopus'
              target='_blank'
              rel='noreferrer'
            >
              <Twitter />
            </a>
            <a
              onMouseEnter={() => menuHover(pinterestPosition.x)}
              onMouseLeave={onCursor}
              ref={pinterestRef}
              href='https://www.pinterest.ca/ayushaopus/'
              target='_blank'
              rel='noreferrer'
            >
              <Pinterest />
            </a>
          </FooterSocial>
        </Flex>
        {/* <FooterCopyright onCursor={onCursor} /> */}
        <div className='copyrightStatement'>
          © 2022 Ayusha Opus Metal Art &Design Inc. <br /> 保留所有权利
        </div>
        <div className='margin_botton_50'>Nothing is written after this</div>
      </Container>
    </FooterNav>
  );
};

export default Footer;
