import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';
// import artist1 from '../../assets/img/artist/artist1.jpg';
// import artist2 from '../../assets/img/artist/artist2.jpg';
// import artist3 from '../../assets/img/artist/artist3.jpg';
// import artist4 from '../../assets/img/artist/artist4.jpg';

export function JumbotronContainer() {
  const size = useWindowSize();
  const artist1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg';
  const artist2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg';
  const artist3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg';
  const artist4 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg';
  return (
    <>
      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <Jumbotron.Container className='artist1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    儿子，父亲，朋友，教师，餐厅老板，建设者，创造者，心脏临床专家，艺术家和愿景家。
                  </p>
                  <p>
                    在Nilesh
                    Bhathella人生的旅程中，一直伴随着各种荣誉和赞美。身为心脏医学和教学领域的专业人士,
                    Nilesh也是天生的艺术家，痴迷于设计，历经数载他才找到独有的艺术表达方式，传达他对生命、哲
                    {/* <br /> */}
                    学和美学的理解与感悟。那么他是如何从那具象的科学领域漫游到这神奇的艺术王国的?
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={artist1}
                  // src={require('../../assets/img/artist/artist1.jpg').default}
                  // src ='https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 2 */}

          <Jumbotron.Container className='artist2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>
                    ilesh认为科学本身就是艺术，通过每一次心跳的振动来表达并创造出“我们”这种终极艺术形式。
                  </p>
                  <p>
                    多年来，在与成千上万患者的互动中，Nilesh有机会从不同的视角看待问题，他总结说，人生最大成就是有能力创造您周围的世界，使其与您个人的能量和梦想产生共鸣，用饶富创意的可视化形式以深刻有意义的方式改善你周围的环境和物质现实。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={artist2}
                  // src={require('../../assets/img/artist/artist2.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 3 */}

          <Jumbotron.Container className='artist3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>
                    ilesh将错综复杂技能和艺术视野进行独特的组合创作，将创造、能量、能量的流动和人类精神都包含在内，并在整个作品中形成共鸣。艺术和科学在此交汇，给予欣赏者纯粹的美感。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  // src  = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  src={artist3}
                  // src={require('../../assets/img/artist/artist3.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}

          <Jumbotron.Container className='artist4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    让这艺术的壮丽将生命的精髓“振动”到你灵魂的最深处，将能量转化为创造。
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    意识孕育、支配并最终成为你的物质世界。
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  src={artist4}
                  // src={require('../../assets/img/artist/artist4.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>意识孕育、支配并最终成为你的物质世界。</p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='artist1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    儿子，父亲，朋友，教师，餐厅老板，建设者，创造者，心脏临床专家，艺术家和愿景家。
                  </p>
                  <p>
                    在Nilesh
                    Bhathella人生的旅程中，一直伴随着各种荣誉和赞美。身为心脏医学和教学领域的专业人士，Nilesh也是天生的艺术家，痴迷于设计，历经数载他才找到独有的艺术表达方式，传达他对生命、哲学和美学的理解与感悟。那么他是如何从那具象的科学领域漫游到这神奇的艺术王国的?
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  // src={require('../../assets/img/artist/artist1.jpg').default}
                  src={artist1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}

          <div className='artist2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>
                    ilesh认为科学本身就是艺术，通过每一次心跳的振动来表达并创造出“我们”这种终极艺术形式。
                  </p>
                  <p>
                    多年来，在与成千上万患者的互动中，Nilesh有机会从不同的视角看待问题，他总结说，人生最大成就是有能力创造您周围的世界，使其与您个人的能量和梦想产生共鸣，用饶富创意的可视化形式以深刻有意义的方式改善你周围的环境和物质现实。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  src={artist2}
                  // src={require('../../assets/img/artist/artist2.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}

          <div className='artist3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    <span>N</span>
                    ilesh将错综复杂技能和艺术视野进行独特的组合创作，将创造、能量、能量的流动和人类精神都包含在内，并在整个作品中形成共鸣。艺术和科学在此交汇，给予欣赏者纯粹的美感。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  src={artist3}
                  // src={require('../../assets/img/artist/artist3.jpg').default}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}

          <div className='artist4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    让这艺术的壮丽将生命的精髓“振动”到你灵魂的最深处，将能量转化为创造。
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    意识孕育、支配并最终成为你的物质世界。
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={artist4}
                  // src={require('../../assets/img/artist/artist4.jpg').default}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>意识孕育、支配并最终成为你的物质世界。</p>
                  </Jumbotron.Text>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
